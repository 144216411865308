import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import MyAddresses from './components/MyAddresses';
import DeliveryAddressesFormContainer from '../DeliveryAddresses/DeliveryAddressesForm/DeliveryAddressesFormContainer';
import {
  setDeliveryAddressesFormInitialValues,
  setDeliveryAddressesFormIsVisible,
  setSelectedDeliveryAdress,
} from '../DeliveryAddresses/deliveryAddressesActions';

const MyAddressesContainer = ({
  getDeliveryAddressesIsRequesting,
  deliveryAddressesList,
  setDeliveryAddressesFormInitialValues,
  setDeliveryAddressesFormIsVisible,
  appConfig,
  setSelectedDeliveryAdress,
}) => {
  return (
    <Fragment>
      <MyAddresses
        getDeliveryAddressesIsRequesting={getDeliveryAddressesIsRequesting}
        deliveryAddressesList={deliveryAddressesList}
        setDeliveryAddressesFormInitialValues={
          setDeliveryAddressesFormInitialValues
        }
        setDeliveryAddressesFormIsVisible={setDeliveryAddressesFormIsVisible}
        appConfig={appConfig}
        setSelectedDeliveryAdress={setSelectedDeliveryAdress}
      />
      {/* <DeliveryAddressesFormContainer /> */}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  deliveryAddressesReducer: {
    getDeliveryAddressesIsRequesting,
    deliveryAddressesList,
  },
}) => ({
  appConfig: state.authReducer.appConfig,
  getDeliveryAddressesIsRequesting,
  deliveryAddressesList,
});

export default connect(mapStateToProps, {
  setDeliveryAddressesFormInitialValues,
  setDeliveryAddressesFormIsVisible,
  setSelectedDeliveryAdress,
})(MyAddressesContainer);
