import React, {Fragment} from 'react';
import styled from 'styled-components';
import {
  Title1,
  Title2,
  Title3,
  Body,
  Headline,
  Subhead,
} from '../../../components/Typography';
import appConfig from '../../../../app-config';
import IQFoodEmptyDeliveryAddresses from './ClientEmptyDeliveryAddresses/IQFoodEmptyDeliveryAddresses';

const NoAddresses = styled.div`
  // margin: 15px 10px;
  padding: 30px 30px;
  border: dashed 2px #979797;
  border-radius: 16px;
  display: grid;
  grid-gap: 12px;
  text-align: center;
  color: ${props => props.theme.darkGray};
`;

const NoAddressesTitle = styled(Title2)`
  color: ${props => props.theme.darkGray};
`;

const EmptyDeliveryAddresses = ({}) => {
  if (appConfig.appSource === 'iQ') {
    return <IQFoodEmptyDeliveryAddresses />;
  }

  return (
    <NoAddresses>
      <NoAddressesTitle
        id="MyAddress-NoAddressesTitle"
        color={`${props => props.theme.darkGray}`}
      >
        No Delivery Addresses{' '}
      </NoAddressesTitle>
      <Body color={`${props => props.theme.darkGray}`}>
        You don't have any delivery addresses stored{' '}
      </Body>
    </NoAddresses>
  );
};

export default EmptyDeliveryAddresses;
