import React from 'react';
import {connect} from 'react-redux';
import Page404 from './404';
import MyAddressesContainer from '../modules/MyAddresses/MyAddressesContainer';
import appConfigFile from '../../app-config';

const MyAddresses = ({isAuthenticated,appConfig}) => {
  if (
    isAuthenticated &&
    (appConfig && appConfig.accepted_order_types.delivery || appConfigFile.isFutureOrderDelivery)
  ) {
    return <MyAddressesContainer />;
  } else {
    return <Page404 />;
  }
};

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated,
});

export default connect(mapStateToProps)(MyAddresses);
