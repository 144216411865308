import React, { Fragment } from 'react';
import styled from 'styled-components';
import {
  Title1,
  Title2,
  Title3,
  Body,
  Headline,
  Subhead,
} from '../../../components/Typography';
import spinner from '../../../assets/spinner.svg';
import formatAddressName from '../../../utils/formatAddressName';
import EmptyDeliveryAddresses from './EmptyDeliveryAddresses';
import appConfig from '../../../../app-config';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${(props) => props.theme.primaryFontFontFamily};
  margin: 20px 0;
  @media screen and (max-width: 768px) {
    margin: 20px 0 0 0;
    flex-direction: column;
  }

  @media screen and (max-width: 360px) {
    margin: 0;
    flex-direction: column;
  }
`;

const Content = styled.div`
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};

  @media screen and (max-width: 767px) {
    margin: 10px 0 0 0;
    width: 400px;
  }
  @media screen and (max-width: 480px) {
    margin: 10px 0 0 0;
    align-items: center;
    width: 100%;
  }
  @media screen and (max-width: 360px) {
    margin: 10px 0 0 0;
    align-items: center;
    width: 100%;
  }
`;

const TitleWrapper = styled.div`
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  @media screen and (max-width: 767px) {
    margin: 10px 0 0 0;
  }
`;

const DeliveryAddressTitle = styled(Title1)`
  padding-bottom: 10px;
  text-transform: ${(props) => props.theme.pageTitleTextTransform};
  width: max-content;
  font-weight: 600;

  :after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    padding-top: 2px;
    border-bottom: ${(props) => props.categoriesBarSelectedBorderColorWidth}
      solid ${(props) => props.theme.categoriesBarSelectedBorderColor};
  }

  @media screen and (max-width: 768px) {
    width: 300px;
  }
`;

const Spinner = styled.img`
  width: 64px;

  margin: auto;
  margin-top: 50px;
`;

const AddressesWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
`;

const AddressCard = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border-bottom: 1px solid ${(props) => props.theme.lightGray};
  font-size: 16px;

  &:hover {
    background: ${(props) => props.theme.orderCardBackgroundColorHover};
    transition: all 0.25s ease;
  }
`;

const DeliveryAddressIcon = styled.i`
  font-size: ${(props) => props.imageSize};
  color: ${(props) => props.imageColor};
`;

const DeliveryAddressImage = styled.img`
  width: 22px;
`;

const NoAddresses = styled.div`
  // margin: 15px 10px;
  padding: 30px 30px;
  border: dashed 2px #979797;
  border-radius: 16px;
  display: grid;
  grid-gap: 12px;
  text-align: center;
  color: ${(props) => props.theme.darkGray};
`;

const NoAddressesTitle = styled(Title2)`
  color: ${(props) => props.theme.darkGray};
`;

const AddressInfoWrapper = styled.div`
  // display: grid;
  // grid-gap: 8px;
  // width: 400px;
  @media (max-width: 400px) {
    // width:300px;
  }
`;

const AddNewAddressWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  border: 1px solid #38373a;
  border-radius: 6px;

  &:hover {
    border: 1px solid #000000;
    transition: all 0.25s ease;
  }
`;

const AddNewAddressHeading = styled.div`
  font-family: ${(props) => props.theme.primaryFontFontFamily};
  font-size: 15px;
`;

const MyTransactionsList = ({
  getDeliveryAddressesIsRequesting,
  deliveryAddressesList,
  setDeliveryAddressesFormIsVisible,
  setDeliveryAddressesFormInitialValues,
}) => {
  return (
    <Wrapper>
      <TitleWrapper margin={'0 70px 10px 0'}>
        <DeliveryAddressTitle id="MyAddress-DeliveryAddressTitle">
          {' '}
          Delivery Addresses
        </DeliveryAddressTitle>
      </TitleWrapper>
      <Content width={'400px'}>
        <AddressesWrapper>
          <div>
            {getDeliveryAddressesIsRequesting ? (
              <Spinner src={spinner} />
            ) : !deliveryAddressesList.length ? (
              <EmptyDeliveryAddresses />
            ) : (
              deliveryAddressesList.map((deliveryAddress, index) => (
                <AddressCard
                  key={index}
                  onClick={() =>
                    setDeliveryAddressesFormInitialValues(deliveryAddress)
                  }
                >
                  {appConfig.accountIcons?.iconType === 'image' ? (
                    <DeliveryAddressImage
                      src={appConfig.accountIcons.icons.my_address}
                    />
                  ) : (
                    <DeliveryAddressIcon
                      className={'fa fa-home'}
                      imageColor={(props) => props.theme.primaryColor}
                      imageSize={'20px'}
                    />
                  )}
                  <AddressInfoWrapper>
                    <Headline
                      id="MyAddress-DeliveryAddressHeadline"
                      textTransform={'capitalize'}
                    >
                      {deliveryAddress.type}
                    </Headline>
                    <div>
                      <Body>{`${deliveryAddress.line1}${
                        deliveryAddress.line2
                          ? `, Suite ${deliveryAddress.line2}`
                          : ''
                      }`}</Body>
                      <Body>{`${deliveryAddress.city}, ${deliveryAddress.state}, ${deliveryAddress.zip}`}</Body>
                    </div>
                  </AddressInfoWrapper>
                  {deliveryAddress.is_preferred ? (
                    <DeliveryAddressIcon
                      className="fas fa-check"
                      imageSize={'14px'}
                      imageColor={(props) => props.theme.darkGray}
                    />
                  ) : null}
                </AddressCard>
              ))
            )}
          </div>
          <div onClick={() => setDeliveryAddressesFormIsVisible(true)}>
            <Headline
              id="MyAddress-AddDeliveryAddressHeadline"
              style={{ marginBottom: '10px' }}
            >
              Add a Delivery Address
            </Headline>
            <AddNewAddressWrapper>
              <AddNewAddressHeading id="MyAddress-AddDeliveryAddressHeading">
                + Add a Delivery Address
              </AddNewAddressHeading>
              <div>
                {' '}
                {appConfig.appSource === 'iQ' ? (
                  <img
                    src="https://s3.amazonaws.com/assets.smoothpay.com/web/iq-foods-assets/delivery-address-icon%403x.png"
                    width="36"
                  />
                ) : (
                  <i
                    className="fas fa-map-marked-alt"
                    style={{ fontSize: '24px' }}
                  ></i>
                )}{' '}
              </div>
            </AddNewAddressWrapper>
          </div>
        </AddressesWrapper>
      </Content>
    </Wrapper>
  );
};

export default MyTransactionsList;
